import React, { useCallback, useEffect, useRef } from 'react';
import { VtmnModal } from '@vtmn/react';
import { createPortal } from 'react-dom';
import { VpIcon, VpIconButton } from '@vtmn-play/react';
import clsx from 'clsx';

import { useAppSelector } from '../../../store/store';
import { selectIsMobile } from '../../../store/selectors/app';

import './modal.css';

interface ModalProps {
  children: JSX.Element;
  title?: string;
  closeModal?: () => void;
}

export const Modal = ({ title, children, closeModal }: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const isMobile = useAppSelector(selectIsMobile);

  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModal?.();
    }
  }, []);

  useEffect(() => {
    modalRef.current?.focus();
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return createPortal(
    <VtmnModal open onClose={closeModal} className={clsx('modal', { isMobile })} data-testid="popin-container">
      <div tabIndex={-1} ref={modalRef} className="modal-header" data-testid="inside">
        {title && <h1 className={clsx('vtmn-typo_title-5', { 'title-mobile': isMobile })}>{title}</h1>}
        {closeModal && (
          <VpIconButton variant="tertiary" size="medium" onClick={closeModal} aria-label="close-modal" data-testid="cross">
            <VpIcon name="close" />
          </VpIconButton>
        )}
      </div>
      <div data-testid="content">{children}</div>
    </VtmnModal>,
    document.body
  );
};
