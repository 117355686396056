import React from 'react';

import { cleanBeforeReload } from '../../../utils/redirectToFed';
import { useI18nStore } from '../../../store/hooks';
import { type Locale } from '../../../store/i18nSlice';
import { ButtonDropdown } from '../../shared/Dropdowns/ButtonDropdown';
import { Flags } from '../../shared/Flags/Flags';
import { TertiaryButton } from '../../shared/Buttons/TertiaryButton';

export const CountryDropdown = () => {
  const { appLanguage, availableLanguages } = useI18nStore();
  const languages = availableLanguages.map(({ country, language }) => ({
    language,
    country,
    selected: language === appLanguage?.language && country === appLanguage?.country,
  }));

  const handleClickOnFlag = (language: Locale) => {
    if (appLanguage?.country !== language.country) {
      cleanBeforeReload(language, null);
    }
  };

  return (
    <ButtonDropdown
      className="text-black"
      label={appLanguage?.language ?? 'EN'}
      startSlot={<Flags lang={appLanguage?.country.toLowerCase() ?? 'gb'} />}
      data-testid="dropdown-language"
    >
      {languages.map((lang, index) => (
        <div className={`${lang.selected ? 'selected-item' : ''}`} key={index}>
          <TertiaryButton
            className="full-width text-black"
            onClick={() => handleClickOnFlag(lang)}
            shape="squared"
            endSlot={<Flags lang={lang.country.toLowerCase()} />}
          >
            {lang.language}
          </TertiaryButton>
        </div>
      ))}
    </ButtonDropdown>
  );
};
