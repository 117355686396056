import { createTypography } from './factory';

import styles from './typography.module.css';

const Body = createTypography({
  displayName: 'Body',
  params: { tag: 'span' },
  stylesMapping: {
    size: {
      s: styles['vp-body-s'],
      m: styles['vp-body-m'],
      l: styles['vp-body-l'],
      caption: styles['vp-caption'],
    },
    color: {
      quiet: styles['text-quiet'],
      neutral: styles['text-neutral'],
      negative: styles['text-negative'],
    },
  },
});

const Title = createTypography({
  displayName: 'Title',
  params: { tag: 'h1' },
  stylesMapping: {
    size: {
      s: styles['vp-title-s'],
      m: styles['vp-title-m'],
      l: styles['vp-title-l'],
      xl: styles['vp-title-xl'],
    },
  },
});

const SubTitle = createTypography({
  displayName: 'SubTitle',
  params: { tag: 'h2' },
  stylesMapping: {
    size: {
      m: styles['vp-subtitle-m'],
      l: styles['vp-subtitle-l'],
    },
    color: {
      quiet: styles['text-quiet'],
      neutral: styles['text-neutral'],
      negative: styles['text-negative'],
    },
  },
});

export const Typography = {
  Title,
  Body,
  SubTitle,
};
