import React from 'react';

import { AppButton, type AppButtonProps } from './AppButton';

export interface TertiaryButtonProps extends Omit<AppButtonProps, 'variant'> {}

export const TertiaryButton = ({ children, ...rest }: TertiaryButtonProps) => (
  <AppButton {...rest} variant="tertiary">
    {children}
  </AppButton>
);
