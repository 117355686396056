import clsx from 'clsx';
import React, { useEffect } from 'react';
import { VtmnButton } from '@vtmn/react';

import { type SearchBarProps } from './SearchBar.types';
import { handleEnter } from '../../../business/eventHelper';

import './search-bar.css';

export const SearchBar = ({
  value = '',
  size = 'small',
  shouldClearSearch = false,
  isGrey = false,
  id = 'searchBar',
  className = '',
  onSearch,
  onClear,
  onChange,
  variant,
  placeholder,
  showSearchButton = true,
  disabled,
  ...rest
}: SearchBarProps) => {
  useEffect(() => {
    /**
     * Quick fix to clear the search input with the close icon.
     * This is a workaround because the VtmnSearch component does not provide a clear method.
     * This is not a good practice and should be removed as soon as the Search component from VitaminPlay is ready.
     */
    if (shouldClearSearch) {
      const closeIcon = document.querySelector<HTMLButtonElement>('[aria-label="close"]');

      closeIcon?.click();
    }
  }, [shouldClearSearch]);

  const [search, setSearch] = React.useState(value);

  useEffect(() => {
    setSearch(value);
  }, [value]);

  const searchValue = () => {
    if (onSearch && search) onSearch(search);
  };

  return (
    <div
      className={clsx(
        'vtmn-search',
        `vtmn-search_variant--${variant}`,
        `vtmn-search_size--${size}`,
        'vp-reskin',
        { 'grey-background': isGrey },
        className
      )}
      role="search"
    >
      <input
        id={id}
        placeholder={placeholder}
        value={search}
        disabled={disabled}
        type="search"
        {...rest}
        onKeyDown={handleEnter(searchValue)}
        onChange={event => {
          onChange?.(event);
          setSearch(event.target.value);
        }}
      />

      <div className="vtmn-search_buttons">
        {search && (
          <VtmnButton
            variant="ghost"
            size={size}
            disabled={disabled}
            iconAlone="close-line"
            onClick={() => {
              setSearch('');
              onClear && onClear();
            }}
            aria-label="close"
            type="button"
          />
        )}

        {showSearchButton && (
          <VtmnButton
            onClick={searchValue}
            variant="ghost"
            size={size}
            disabled={disabled}
            type="submit"
            iconAlone="search-line"
            aria-label="search"
          />
        )}
      </div>
    </div>
  );
};
