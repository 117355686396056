import React, { type ReactNode, useState } from 'react';
import { VpIcon } from '@vtmn-play/react';
import { Case } from '@chipp972/react-case-when';
import { v4 } from 'uuid';

import useClickOutside from '../../../utils/hooks/useClickOutside';
import { AppButton, type AppButtonProps } from '../Buttons/AppButton';

import style from './button-dropdown.module.css';

interface ButtonDropdownProps {
  label: string;
  children: ReactNode;
  className?: string;
  startSlot?: ReactNode;
  variant?: AppButtonProps['variant'];
  id?: string;
}

export const ButtonDropdown = ({ label, children, id, ...rest }: ButtonDropdownProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const dropdownRef = useClickOutside<HTMLDivElement>(setIsVisible);
  const tagId = id ?? v4();
  const buttonProps = { ...rest, id: tagId };

  return (
    <div ref={dropdownRef} className={style['dropdown-container']}>
      <AppButton {...buttonProps} onClick={() => setIsVisible(!isVisible)} endSlot={<VpIcon name="chevron-down" />}>
        {label}
      </AppButton>
      <Case when={isVisible}>
        <div className={style['dropdown-content']}>{children}</div>
      </Case>
    </div>
  );
};
