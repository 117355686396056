import { useSelector } from 'react-redux';

import { selectIsBookingsEnabled, selectSparePartsOrder } from '../../store/selectors/userInfo';
import { RoutesPath, getAppRoute } from '../../core/routes';
import { DEFAULT_ACTIVE_TAB } from '../../pages/SparePartOrders/constants';
import { useDataStudio } from '../../utils/hooks/useDataStudio';
import { selectHasRWRelatedStores } from '../../store/store-list';

export const useNavigationLinks = () => {
  const isSparePartsOrderEnabled = useSelector(selectSparePartsOrder);
  const isBookingsEnabled = useSelector(selectIsBookingsEnabled);
  const hasRWRelatedStores = useSelector(selectHasRWRelatedStores);
  const dataStudioLink = useDataStudio();

  return [
    {
      label: 'home',
      route: getAppRoute(RoutesPath.homePage),
      icon: 'home',
      exactPath: true,
      enabled: true,
      externalLink: false,
      dataTestId: 'button-home',
      event: 'home',
      isNew: false,
    },
    {
      label: 'bookings',
      route: getAppRoute(RoutesPath.bookingsPage),
      icon: 'user',
      enabled: isBookingsEnabled,
      externalLink: false,
      dataTestId: 'button-bookings',
      event: 'bookings',
      isNew: true,
    },
    {
      label: 'planning',
      route: getAppRoute(RoutesPath.planningPage),
      icon: 'calendar',
      exactPath: false,
      enabled: true,
      externalLink: false,
      dataTestId: 'button-planning',
      event: 'planning',
      isNew: false,
    },
    {
      label: 'interventions',
      route: getAppRoute(RoutesPath.searchPage),
      icon: 'tools',
      exactPath: false,
      enabled: true,
      externalLink: false,
      dataTestId: 'button-interventions',
      event: 'interventions',
      isNew: false,
    },
    {
      label: 'parcels.flow',
      route: getAppRoute(RoutesPath.parcelFlowPage),
      icon: 'truck',
      exactPath: false,
      enabled: true,
      externalLink: false,
      dataTestId: 'button-parcels',
      event: 'parcels_flow',
      isNew: false,
    },
    {
      label: 'order',
      route: { pathname: getAppRoute(RoutesPath.sparePartOrders), search: `?activeTab=${DEFAULT_ACTIVE_TAB}` },
      icon: 'shopping-bag',
      exactPath: true,
      enabled: isSparePartsOrderEnabled,
      externalLink: false,
      dataTestId: 'button-order',
      event: 'order',
      isNew: true,
    },
    {
      label: 'dashboard.report',
      route: dataStudioLink,
      icon: 'bar-chart',
      enabled: true,
      externalLink: true,
      dataTestId: 'button-report',
      event: 'report',
      isNew: false,
    },
    {
      label: 'useful.links',
      route: 'https://sites.google.com/decathlon.com/workshop-it-informations/liens-utiles-zone-douani%C3%A8re',
      icon: 'external-link',
      enabled: true,
      externalLink: true,
      dataTestId: 'button-useful-links',
      event: 'external_links',
      isNew: false,
    },
    {
      label: 'parameters',
      route: getAppRoute(RoutesPath.delayParamPage),
      icon: 'settings',
      enabled: hasRWRelatedStores,
      externalLink: false,
      dataTestId: 'button-settings',
      event: 'delays_parameters',
      isNew: false,
    },
  ] as const;
};
