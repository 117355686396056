import React, { forwardRef } from 'react';
import classNames from 'clsx';

import { type TypographyProps, type TypographyTags } from './types';

interface TypographyParams {
  tag: TypographyTags;
  className?: string;
}

interface CreateTypographyParams<S> {
  displayName: string;
  params: TypographyParams;
  stylesMapping: S;
}

export const createTypography = <S extends Record<string, Record<string, string>> = {}>({
  displayName,
  params,
  stylesMapping,
}: CreateTypographyParams<S>) => {
  const TypographyItem = forwardRef(
    <E extends TypographyTags>(
      { as, className = '', children, ...props }: TypographyProps<E, { [key in keyof S]: keyof S[key] }>,
      ref: React.ForwardedRef<HTMLElement>
    ) => {
      const TypographyComponent: string = as ?? params.tag;
      const cssClasses = Object.keys(stylesMapping).map(property => {
        const propertyValue = String(props[property]);

        return stylesMapping[property][propertyValue];
      });
      const classes = classNames(params.className, className, ...cssClasses);

      return (
        <TypographyComponent ref={ref} className={classes} {...props}>
          {children}
        </TypographyComponent>
      );
    }
  );

  TypographyItem.displayName = displayName;

  return TypographyItem;
};
