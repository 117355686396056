// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Qz88m6BDkflKpMpTcTWENg\\=\\={position:relative}.Qz88m6BDkflKpMpTcTWENg\\=\\= .NNz69YXoRAxPF6nTktEoig\\=\\={background-color:var(--vp-semantic-color-background-main);border:1px solid var(--vp-semantic-color-border-quiet);border-radius:var(--vp-semantic-border-radius-s);max-height:200px;overflow-y:auto;padding:var(--vp-semantic-spacing-s);position:absolute}", "",{"version":3,"sources":["webpack://./src/components/shared/Dropdowns/button-dropdown.module.css"],"names":[],"mappings":"AAAA,4BACE,iBAWF,CATE,wDAIE,yDAA0D,CAC1D,sDAAuD,CACvD,gDAAiD,CAJjD,gBAAiB,CAKjB,eAAgB,CAJhB,oCAAqC,CAFrC,iBAOF","sourcesContent":[".dropdown-container {\n  position: relative;\n\n  .dropdown-content {\n    position: absolute;\n    max-height: 200px;\n    padding: var(--vp-semantic-spacing-s);\n    background-color: var(--vp-semantic-color-background-main);\n    border: 1px solid var(--vp-semantic-color-border-quiet);\n    border-radius: var(--vp-semantic-border-radius-s);\n    overflow-y: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown-container": "Qz88m6BDkflKpMpTcTWENg==",
	"dropdown-content": "NNz69YXoRAxPF6nTktEoig=="
};
export default ___CSS_LOADER_EXPORT___;
