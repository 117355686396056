import React from 'react';
import { VpAsset, type VpAssetProps } from '@vtmn-play/react';

import TWFlag from '../../../imgs/icons/flag-TW.png';

import styles from './flags.module.css';

interface FlagsProps {
  lang: string;
  className?: string;
}

export const Flags = ({ lang, className }: FlagsProps) => {
  // see WKSICARE-1386
  if (lang === 'tw') {
    return <img src={TWFlag} alt="" className={styles.twFlag} />;
  }

  return <VpAsset className={className} name={`flag-${lang}` as VpAssetProps['name']} />;
};
