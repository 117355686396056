// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (min-width:900px){.OAUYGvfWoL5i727a\\+RnCgA\\=\\={margin-left:var(--menu-toolbar-width)}}", "",{"version":3,"sources":["webpack://./src/components/PageLayout/PageLayout.module.css"],"names":[],"mappings":"AAAA,oCACE,6BACE,qCACF,CACF","sourcesContent":["@media screen and (min-width: 900px) {\n  .content {\n    margin-left: var(--menu-toolbar-width);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "OAUYGvfWoL5i727a+RnCgA=="
};
export default ___CSS_LOADER_EXPORT___;
