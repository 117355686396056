// @ts-strict-ignore
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type PersonTypes, type Customer, type CustomerInput, type UnauthorizedCustomer } from '../apis/customer/customer.types';

export interface CustomerState {
  customer?: Customer | null;
  customerInput?: CustomerInput | null;
}

export const initialCustomerState: CustomerState = {
  customer: null,
  customerInput: null,
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState: initialCustomerState,
  extraReducers: builder => builder.addCase('resetStore', () => initialCustomerState),
  reducers: {
    selectCustomer: (state, action: PayloadAction<Customer | null>) => {
      state.customer = action.payload;
    },
    setUnauthorizedCustomer: (state, action: PayloadAction<UnauthorizedCustomer>) => {
      state.customer = { ...state.customer, personType: action.payload.personType, isUnauthorized: action.payload.isUnauthorized };
    },
    setPersonType: (state, action: PayloadAction<PersonTypes>) => {
      state.customer = { ...state.customer, personType: action.payload };
    },
    deleteCustomer: state => {
      state.customer = null;
    },
    updateCustomerInput: (state, action: PayloadAction<CustomerInput>) => {
      state.customerInput = action.payload;
    },
  },
});

export const {
  reducer: customerReducer,
  actions: { selectCustomer, updateCustomerInput, deleteCustomer, setPersonType, setUnauthorizedCustomer },
} = customerSlice;
