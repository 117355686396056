// @ts-strict-ignore
import React, { useDeferredValue, useMemo, useState } from 'react';
import { VtmnDivider } from '@vtmn/react';
import { VpIcon } from '@vtmn-play/react';
import { Case, Switch } from '@chipp972/react-case-when';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectAllowedStores } from '../../../store/store-list';
import { cleanBeforeReload } from '../../../utils/redirectToFed';
import { ButtonDropdown } from '../../shared/Dropdowns/ButtonDropdown';
import { SearchBar } from '../../shared/Searches/SearchBar';
import { TertiaryButton } from '../../shared/Buttons/TertiaryButton';
import { selectSelectedSite } from '../../../store/selectors/userInfo';

const replaceLatin = /[\u0300-\u036f]/g;
const replaceSpecial = /'|\/\|_|,|\.|\(|\)/g;
const normalizationFormCanonicalDecomposition = 'NFD';

const normalizeSearch = (txt: string) => txt.normalize(normalizationFormCanonicalDecomposition).replace(replaceLatin, '').replace(replaceSpecial, '');

export const StoresDropdown = () => {
  const [storeInput, setStoreInput] = useState('');
  const { t } = useTranslation();
  const selectedSite = useSelector(selectSelectedSite);
  const allowedStores = useSelector(selectAllowedStores);
  const deferredSearch = useDeferredValue(storeInput);

  const setChosenStore = (storeName: string) => {
    const store = allowedStores.find(site => site.id === Number(storeName.split('-')[0].trim()) || site.name === storeName);

    if (store && store.id !== selectedSite.id) {
      cleanBeforeReload(null, store.id);
    }
  };

  const handleStoreSearch = (input: string) => {
    const findStore = allowedStores.find(store => store.id.toString() === input || store.name === input.toUpperCase());

    if (findStore) {
      cleanBeforeReload(null, findStore.id);
    }
  };

  const stores = useMemo(() => {
    const searchedStore = new RegExp(deferredSearch || '.*', 'i');

    return allowedStores.reduce((acc, store, index) => {
      const txt = `${store.id} - ${store.name}`;
      const normalizedStoreInput = normalizeSearch(txt);

      if (searchedStore.test(normalizedStoreInput)) {
        return [
          ...acc,
          <div key={index} className={`${store.id === selectedSite.id ? 'selected-item' : ''}`}>
            <TertiaryButton
              className="store-list-item text-black"
              data-testid="store-list-item"
              size="small"
              shape="squared"
              onClick={() => setChosenStore(store.name)}
            >
              {txt}
            </TertiaryButton>
            <VtmnDivider />
          </div>,
        ];
      }

      return acc;
    }, []);
  }, [deferredSearch]);

  return (
    <Switch>
      <Case when={allowedStores.length > 1}>
        <ButtonDropdown className="text-black" label={selectedSite.name} startSlot={<VpIcon name="map-pin" />} data-testid="dropdown-stores">
          <div data-testid="stores-dropdown">
            <SearchBar
              placeholder={t('toolbar.top.userinfos.placeholder')}
              value={storeInput}
              onChange={event => setStoreInput(event.target.value)}
              onSearch={handleStoreSearch}
              onClear={() => setStoreInput('')}
              data-testid="stores-list-search-bar"
              autoFocus
            />

            {stores}
          </div>
        </ButtonDropdown>
      </Case>

      <Case>
        <div className="selected-site-container">
          <VpIcon name="map-pin" />
          <span className="selected-site">{selectedSite.name}</span>
        </div>
      </Case>
    </Switch>
  );
};
