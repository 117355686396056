// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".C\\+MbLVf5NS2iEDRn5xcKeQ\\=\\={align-items:flex-start;display:flex;flex-direction:column;gap:var(--vp-semantic-spacing-m);justify-content:center}.JEPWbT31HVY9Uu2iGxH7Mg\\=\\={white-space:pre-line}.FSi7R9Pk-3bekXc7TkCRxQ\\=\\={align-self:center;height:164px;width:164px}", "",{"version":3,"sources":["webpack://./src/pages/Bookings/booking-leave-intervention/LeaveInterventionFromBookingConfirmationModal.module.css"],"names":[],"mappings":"AAAA,6BAIE,sBAAuB,CAHvB,YAAa,CACb,qBAAsB,CAGtB,gCAAiC,CAFjC,sBAGF,CAEA,4BACE,oBACF,CAEA,4BAGE,iBAAkB,CAFlB,YAAa,CACb,WAEF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  gap: var(--vp-semantic-spacing-m);\n}\n\n.message {\n  white-space: pre-line;\n}\n\n.image {\n  height: 164px;\n  width: 164px;\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "C+MbLVf5NS2iEDRn5xcKeQ==",
	"message": "JEPWbT31HVY9Uu2iGxH7Mg==",
	"image": "FSi7R9Pk-3bekXc7TkCRxQ=="
};
export default ___CSS_LOADER_EXPORT___;
