import React from 'react';
import { VpButton, type VpButtonProps } from '@vtmn-play/react';

export interface AppButtonProps extends VpButtonProps {}

export const AppButton = ({
  children,
  variant = 'tertiary',
  size = 'small',
  shape = 'rounded',
  disabled = false,
  type = 'button',
  ...rest
}: AppButtonProps) => (
  <VpButton {...rest} variant={variant} size={size} shape={shape} disabled={disabled} type={type}>
    {children}
  </VpButton>
);
