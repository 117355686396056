import React from 'react';
import clsx from 'clsx';

import './loader.scss';

export interface LoaderProps {
  color?: 'black' | 'white' | 'orange';
  width?: '' | 'small';
  className?: string;
}

export default ({ color = 'black', width = '', className = '' }: LoaderProps) => (
  <div className={clsx('loader-container', color, width, className)} role="progressbar" aria-label="loading" data-testid="popin-loader" />
);
