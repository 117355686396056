import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LeaveInterventionFromBookingConfirmationModal } from './LeaveInterventionFromBookingConfirmationModal';
import { resetBooking } from '../../../store/bookingSlice';
import { selectBookingId } from '../../../store/selectors/booking';

interface UseNoShowConfirmationModalParams {
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const useLeaveInterventionFromBookingConfirmationModal = ({ onConfirm, onCancel }: UseNoShowConfirmationModalParams = {}) => {
  const [modal, setModal] = useState<JSX.Element | null>(null);
  const dispatch = useDispatch();
  const bookingId = useSelector(selectBookingId);

  const confirmLeaveInterventionFromBookingConfirmationModal = () =>
    new Promise<boolean>(resolve => {
      if (!bookingId) {
        resolve(true);
        return;
      }

      const handleConfirm = () => {
        dispatch(resetBooking());
        setModal(null);
        onConfirm?.();
        resolve(true);
      };

      const handleCancel = () => {
        setModal(null);
        onCancel?.();
        resolve(false);
      };

      setModal(<LeaveInterventionFromBookingConfirmationModal onConfirm={handleConfirm} onCancel={handleCancel} />);
    });

  return {
    LeaveInterventionFromBookingConfirmationModal: () => modal,
    confirmLeaveInterventionFromBookingConfirmationModal,
  };
};
