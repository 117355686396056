// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xyicCLcPkdr0T1mCAl7OkQ\\=\\={align-items:stretch;flex-direction:column;width:690px}.UfuyJwnzahwM1NdLaCcJEA\\=\\=,.xyicCLcPkdr0T1mCAl7OkQ\\=\\={display:flex;gap:var(--vp-semantic-spacing-m);padding:0}.UfuyJwnzahwM1NdLaCcJEA\\=\\={align-items:center;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./src/components/shared/Modals/ConfirmModal.module.css"],"names":[],"mappings":"AAAA,4BAGE,mBAAoB,CADpB,qBAAsB,CAItB,WACF,CAEA,wDARE,YAAa,CAGb,gCAAiC,CACjC,SAUF,CANA,4BAGE,kBAAmB,CADnB,wBAIF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  gap: var(--vp-semantic-spacing-m);\n  padding: 0;\n  width: 690px;\n}\n\n.buttonsGroup {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  gap: var(--vp-semantic-spacing-m);\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "xyicCLcPkdr0T1mCAl7OkQ==",
	"buttonsGroup": "UfuyJwnzahwM1NdLaCcJEA=="
};
export default ___CSS_LOADER_EXPORT___;
