import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Typography } from '../../../components/shared/Typographies/Typography';
import { ConfirmModal } from '../../../components/shared/Modals/ConfirmModal';
import BeardmanError2 from '../../../imgs/caracters/beardman-error2.svg';
import { resetBooking } from '../../../store/bookingSlice';

import styles from './LeaveInterventionFromBookingConfirmationModal.module.css';

interface LeaveInterventionConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export const LeaveInterventionFromBookingConfirmationModal = ({ onConfirm, onCancel }: LeaveInterventionConfirmationModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleConfirm = () => {
    dispatch(resetBooking());
    onConfirm();
  };

  return (
    <ConfirmModal title={t('bookings.confirm.leave.intervention.popin.title')} onConfirm={handleConfirm} onCancel={onCancel}>
      <div className={styles.container}>
        <Typography.Body color="neutral" size="m" className={styles.message}>
          {t('bookings.confirm.leave.intervention.popin.message')}
        </Typography.Body>
        <img src={BeardmanError2} alt="" className={styles.image} />
      </div>
    </ConfirmModal>
  );
};
