import React, { type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from './Modal';
import { SecondaryButton } from '../Buttons/SecondaryButton';
import { PrimaryButton } from '../Buttons/PrimaryButton';

import styles from './ConfirmModal.module.css';

interface ConfirmModalProps extends PropsWithChildren {
  title?: string;
  confirmLabel?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmModal = ({ title, confirmLabel, onConfirm, onCancel, children }: ConfirmModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal title={title} closeModal={onCancel}>
      <div className={styles.container}>
        {children}
        <div className={styles.buttonsGroup}>
          <SecondaryButton onClick={onCancel} size="medium">
            {t('popin.button.cancel')}
          </SecondaryButton>
          <PrimaryButton data-testid="button-confirm-modal" onClick={onConfirm} size="medium">
            {confirmLabel || t('popin.button.confirm')}
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
