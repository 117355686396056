import React, { type MouseEvent } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { VpIcon } from '@vtmn-play/react';

import { ReactComponent as Bookings } from '../../imgs/icons/bookings.svg';
import { useProductContext } from '../ProductPage/ProductProvider';
import { useReset } from '../../store/hooks';
import { useCountryContext } from '../../utils/countryProvider/CountryProvider';
import { selectSiteCountryCode } from '../../store/selectors/userInfo';
import { getCountryCode } from '../../business/customerHelper';
import LogoProd from '../../imgs/logos/prod.svg';
import LogoPreprod from '../../imgs/logos/preprod.svg';
import { pushMenuEventGTM } from '../../utils/GTM/googleTagManager';
import { useLeaveInterventionFromBookingConfirmationModal } from '../../pages/Bookings/booking-leave-intervention/useLeaveInterventionFromBookingConfirmationModal';
import { useNavigationLinks } from './useNavigationLinks';
import { Label } from '../shared/Label/Label';
import { selectIsProd } from '../../store/selectors/configuration';

import styles from './ToolbarMenu.module.css';

export const ToolbarMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { emptyIntervention } = useReset();
  const { emptyNatureCategories } = useProductContext();
  const { countries, setCustomerCountry } = useCountryContext();
  const siteCountryCode = useSelector(selectSiteCountryCode);
  const isProd = useSelector(selectIsProd);
  const { LeaveInterventionFromBookingConfirmationModal, confirmLeaveInterventionFromBookingConfirmationModal } =
    useLeaveInterventionFromBookingConfirmationModal();
  const navLinks = useNavigationLinks();

  const handleNavigationClick = async (event: MouseEvent, route: string | Record<string, unknown>, eventLabel: string) => {
    event.preventDefault();
    event.stopPropagation();

    const leavingCancelled = !(await confirmLeaveInterventionFromBookingConfirmationModal());

    if (leavingCancelled) {
      return;
    }

    emptyIntervention();
    emptyNatureCategories();
    pushMenuEventGTM(eventLabel);

    const currentCountry = countries.find(country => getCountryCode(country)?.toUpperCase() === siteCountryCode.toUpperCase());

    if (currentCountry) {
      setCustomerCountry(currentCountry);
    }

    navigate(route);
  };

  return (
    <div aria-label="Navigation" className={styles.content}>
      <div className={styles.logo}>
        <img src={isProd ? LogoProd : LogoPreprod} alt="icare" />
      </div>
      {navLinks
        .filter(link => link.enabled)
        .map(link => {
          if (link.externalLink) {
            return (
              <a
                aria-label="Navigation item"
                className={styles.item}
                href={link.route}
                key={link.label}
                target="_blank"
                rel="noreferrer"
                data-testid={link.dataTestId}
                onClick={() => pushMenuEventGTM(link.event)}
              >
                <div className={styles.icon}>
                  <VpIcon name={link.icon} />
                  <span className={styles.text}>{t(`toolbar.menu.${link.label}`)}</span>
                </div>
              </a>
            );
          }

          return (
            <NavLink
              aria-label="Navigation item"
              className={navData => clsx(styles.item, { [styles.selected]: navData.isActive })}
              to={link.route}
              key={link.label}
              onClick={event => handleNavigationClick(event, link.route, link.event)}
              data-testid={link.dataTestId}
            >
              <div className={styles.icon}>
                {/* Booking icon is a self made icon, and does not exists yet in Vitamin DS */}
                {link.label === 'bookings' ? <Bookings /> : <VpIcon name={link.icon} />}
                <span className={styles.text}>{t(`toolbar.menu.${link.label}`)}</span>
                {link.isNew && <Label className={styles.newLabel}>New</Label>}
              </div>
            </NavLink>
          );
        })}
      <LeaveInterventionFromBookingConfirmationModal />
    </div>
  );
};
