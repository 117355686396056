import React from 'react';

import { AppButton, type AppButtonProps } from './AppButton';

export interface SecondaryButtonProps extends Omit<AppButtonProps, 'variant'> {}

export const SecondaryButton = ({ children, ...rest }: SecondaryButtonProps) => (
  <AppButton {...rest} variant="secondary">
    {children}
  </AppButton>
);
