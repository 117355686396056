import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useReduxAjax, RequestMethod, type ReduxAjaxParams } from '@chipp972/redux-ajax';
import { useSelector } from 'react-redux';

import type { SearchResult, UseFetchSearchResultParams } from './search.types';
import { LevelType } from '../ilink.type';
import { buildUrl, useBuildHeader } from '../../utils/apis/RestApi';
import { type BreadcrumbLevel, resetProduct, updateCatalogSearch } from '../../store/catalogSlice';
import translate from '../../utils/i18n/SwappingIntlProvider';
import { useAppDispatch } from '../../store/store';
import { addFromTraceability, initInterventionState } from '../../store/interventionSlice';
import { deleteCustomer } from '../../store/customerSlice';
import { selectCountryId, selectStoreId } from '../../store/selectors/userInfo';
import { type CatalogProductRaw } from '../masterdata/masterdata.type';
import { selectCatalogSearch } from '../../store/selectors/catalog';
import { getAppRoute, RoutesPath } from '../../core/routes';

export const useSearchFoundDispatch = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const catalogSearch = useSelector(selectCatalogSearch);

  const handleProductFound = (catalogProduct: CatalogProductRaw) => {
    const { product, productInformation, hasSpareParts } = catalogProduct;

    if (!productInformation) return;

    const updatedBreadcrumb: BreadcrumbLevel[] = [
      { ...catalogSearch.breadcrumb[0], hide: true },
      { level: LevelType.categories, label: productInformation.process.name, id: productInformation.process.id, hide: true },
      { level: LevelType.products, label: productInformation.category.name, id: productInformation.category.id, hide: true },
      { level: LevelType.product, label: translate('catalog.breadcrumb.search.product') },
    ];

    dispatch(
      updateCatalogSearch({
        ...catalogSearch,
        product: {
          ...product,
          purchaseDate: product.purchaseDate ? new Date(product.purchaseDate) : undefined,
          hasSpareParts,
        },
        breadcrumb: updatedBreadcrumb,
        fetchNeeded: true,
      })
    );

    dispatch(initInterventionState());
  };

  const handleInterventionFound = (interventionNumber: string) => {
    const interventionRoute = getAppRoute(RoutesPath.interventionPage, interventionNumber);

    if (interventionRoute === pathname) {
      return;
    }

    dispatch(resetProduct());
    dispatch(deleteCustomer());
    dispatch(addFromTraceability());
  };

  return { handleProductFound, handleInterventionFound };
};

export const useFetchSearchResult = ({ requestType }: UseFetchSearchResultParams) => {
  const countryId = useSelector(selectCountryId);
  const storeId = useSelector(selectStoreId);
  const headers = useBuildHeader({ serviceName: 'ICARE_BACK', tokenType: 'Bearer', fullLocale: false });
  const request = useReduxAjax<SearchResult | null>(`${requestType}-request`);
  const result = request.response;

  const fetchResult = useCallback(
    (input: string) => {
      const url = buildUrl('ICARE_BACK', `search/${input}?countryId=${countryId}&storeId=${storeId}`);
      const requestContent: ReduxAjaxParams = {
        url,
        headers,
        method: RequestMethod.get,
      };

      request.submitRequest({ requestContent });
    },
    [countryId, storeId]
  );

  return {
    result,
    fetchResult,
    isRequestPending: request.isRequestPending,
    isRequestFailed: request.isRequestFailed,
    resetResponse: request.resetResponse,
    resetRequest: request.resetRequest,
  };
};
