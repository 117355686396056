import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { getAppRoute, RoutesPath } from '../../../core/routes';
import { selectIsRegionalWorkshopSelector, selectNewCustomerPurchasesPage, selectSparePartsOrder } from '../../../store/selectors/userInfo';
import { colors, externalToastStore } from '../../../utils/toaster/ToastProvider';
import { useFetchSearchResult, useSearchFoundDispatch } from '../../../apis/search/search';
import { SearchBar } from '../../shared/Searches/SearchBar';
import { CountryDropdown } from './CountryDropdown';
import { LogoutDropdown } from './LogoutDropdown';
import { StoresDropdown } from './StoresDropdown';
import { useLeaveInterventionFromBookingConfirmationModal } from '../../../pages/Bookings/booking-leave-intervention/useLeaveInterventionFromBookingConfirmationModal';
import { deleteCustomer } from '../../../store/customerSlice';
import { useAppDispatch } from '../../../store/store';

import './header.css';

const pathNames = {
  product: '/product/intervention',
  intervention: '/intervention',
  shippingGroup: '/shipping-group',
};

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { handleProductFound, handleInterventionFound } = useSearchFoundDispatch();
  const { result, isRequestPending, isRequestFailed, fetchResult, resetRequest } = useFetchSearchResult({ requestType: 'search' });
  const { t } = useTranslation();
  const isRegionalWorkshop = useSelector(selectIsRegionalWorkshopSelector);
  const newCustomerPurchasesPage = useSelector(selectNewCustomerPurchasesPage);
  const sparePartsOrder = useSelector(selectSparePartsOrder);
  const [searchInput, setSearchInput] = useState('');
  const { LeaveInterventionFromBookingConfirmationModal, confirmLeaveInterventionFromBookingConfirmationModal } =
    useLeaveInterventionFromBookingConfirmationModal();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isRequestPending) return;

    if (isRequestFailed) {
      externalToastStore.addToast(
        `${t('error.message.product.scan.failed.title')}.\n${t('error.message.product.scan.failed.description')}`,
        colors.red
      );
    }

    if (result) {
      if (result.type === 'product' && result.catalogProduct) {
        handleProductFound(result.catalogProduct);
        dispatch(deleteCustomer());
      }
      if (result.type === 'intervention' && result.value) handleInterventionFound(result.value);

      const newPathname = [pathNames[result.type], result.value].filter(Boolean).join('/');

      navigate(newPathname, {
        state: {
          hasSpareParts: result.catalogProduct?.hasSpareParts,
        },
      });
    }

    resetRequest();
  }, [isRequestPending]);

  const handleSearch = async (input: string) => {
    if (!(await confirmLeaveInterventionFromBookingConfirmationModal())) {
      return;
    }
    if (pathname === getAppRoute(RoutesPath.interventionPage, input)) {
      location.reload();
    } else {
      fetchResult(input);
    }
  };

  const shouldUseWhiteBackground =
    (sparePartsOrder && pathname.includes(getAppRoute(RoutesPath.sparePartOrders))) ||
    (newCustomerPurchasesPage && pathname.includes(getAppRoute(RoutesPath.customerPage)));

  return (
    <div className={clsx('new-toolbar-container', { white: shouldUseWhiteBackground })}>
      <div className="toolbar-search">
        <SearchBar
          placeholder={t('toolbar.top.search.placeholder')}
          onSearch={handleSearch}
          value={searchInput}
          onChange={event => setSearchInput(event.target.value.replace(/\s/g, ''))}
          autoFocus={isRegionalWorkshop}
          isGrey={![getAppRoute(RoutesPath.homePage)].includes(pathname)}
          shouldClearSearch={Boolean(result)}
          data-testid="360-search-bar"
        />
      </div>

      <div className="dropdowns-container">
        <StoresDropdown />
        <LogoutDropdown />
        <CountryDropdown />
      </div>
      <LeaveInterventionFromBookingConfirmationModal />
    </div>
  );
};

export default Header;
