import { useSelector } from 'react-redux';

import { selectSelectedSite } from '../../store/selectors/userInfo';

export const useDataStudio = () => {
  const { id: storeId, name, address } = useSelector(selectSelectedSite);
  const country = address.countryCode.toUpperCase();

  const baseUrl =
    'https://prod-uk-a.online.tableau.com/#/site/dktunited/views/Workshop-HomePage_17283911461640/Homepage?:' +
    `iid=${storeId}&Country=${country}&Creation Site=${name}`;

  return encodeURI(baseUrl);
};
