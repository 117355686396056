import React from 'react';
import { useSelector } from 'react-redux';

import ToolbarTopMobile from '../ToolbarTop/ToolbarTopMobile';
import { selectIsMobile } from '../../store/selectors/app';
import { ToolbarMenu } from '../ToolbarMenu/ToolbarMenu';
import Header from '../ToolbarTop/Header/Header';

import styles from './PageLayout.module.css';

interface PageLayoutProps {
  children: JSX.Element;
}

export const PageLayout = ({ children }: PageLayoutProps) => {
  const isMobile = useSelector(selectIsMobile);

  return (
    <main className="vtmn-new-visual-identity">
      {isMobile ? <ToolbarTopMobile /> : <ToolbarMenu />}
      <div className={styles.content}>
        {!isMobile && <Header />}
        {children}
      </div>
    </main>
  );
};
