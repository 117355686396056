import React from 'react';
import cache from 'lscache';
import { VpIcon } from '@vtmn-play/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { logout } from '../../../apis/userinfo';
import { selectAuthService } from '../../../store/selectors/auth';
import useToaster from '../../../utils/hooks/useToaster';
import { selectUserDisplayName } from '../../../store/selectors/userInfo';
import { AppButton } from '../../shared/Buttons/AppButton';
import { ButtonDropdown } from '../../shared/Dropdowns/ButtonDropdown';
import { isNewAuthVersion } from '../../../utils/storage/localStorage';

export const LogoutDropdown = () => {
  const displayName = useSelector(selectUserDisplayName);
  const authService = useSelector(selectAuthService);
  const { addToast, red } = useToaster();
  const { t } = useTranslation();

  const handleLogout = async () => {
    const isNewAuth = isNewAuthVersion();

    try {
      await logout();

      const hasCachedKeys = Object.keys(localStorage).some(storageKey => storageKey.includes('lscache'));

      if (hasCachedKeys) {
        cache.flush();
      }

      if (!isNewAuth) {
        await authService.signinRedirect();
      }
    } catch (error) {
      addToast(t('error.generic.message'), red);
    }
  };

  return (
    <ButtonDropdown className="text-black" label={displayName} startSlot={<VpIcon name="user" />} data-testid="dropdown-userinfo">
      <AppButton className="text-black" onClick={handleLogout} startSlot={<VpIcon name="run" />} data-testid="logout-button">
        {t('toolbar.top.userinfos.button.logout')}
      </AppButton>
    </ButtonDropdown>
  );
};
