import { type UseMutateAsyncFunction } from '@tanstack/react-query';

import store from '../store/store';
import { hasKnownProduct } from '../store/selectors/catalog';
import translate, { type I18nKey } from '../utils/i18n/SwappingIntlProvider';
import { colors, externalToastStore } from '../utils/toaster/ToastProvider';
import { type Article, type MasterDataStore } from '../apis/masterdata/masterdata.type';
import { InterventionStatus } from './interventionButtonMenu';
import {
  type WorkshopIntervention,
  InterventionType,
  type WorkshopContextType,
  type FillInterventionsInformationParams,
  CustomerAlertType,
  type WboInterventionSearchRaw,
  type UrlForGlobalSearchParams,
  InterventionContextId,
  type ConvertCustomerAlertType,
  InterventionsEventsType,
} from '../apis/interventions/intervention.type';
import { type Intervention, type SortElement } from '../components/Interventions/Interventions';
import { initialState, initIntervention, setMissingFields } from '../store/interventionSlice';
import { type Entity, type ILinkProduct } from '../apis/ilink.type';
import { type Customer, type CustomersLightDetails, type Language, PersonTypes } from '../apis/customer/customer.types';
import { getInterventionsByIds, getInterventionsIds, updateIntervention } from '../apis/interventions/interventions';
import { hasNoStock } from './stockHelper';
import { initialBreadcrumb } from '../components/Breadcrumb/Breadcrumb';
import { arrayUniqueByKey, getFuturePledgeDate } from '../utils/utils';
import { getArticlesDetails } from '../apis/masterdata/masterdata';
import { initialProductCatalog, updateCatalogSearch } from '../store/catalogSlice';
import { findPriceless, findWarranty, getOgeaAmount } from './priceHelper';
import { isInterventionOgea } from './entityHelper';
import { findSelectedArticle } from './articleHelper';
import { getIsInternalIntervention, isExistingIntervention, isPogoAuthorized } from '../store/selectors/intervention';
import { Pogos } from '../apis/interventions/intervention.const';
import { getDecathlonCustomerName } from './customerHelper';
import { getCustomersLightDetails } from '../apis/member/member';
import { selectAllStores } from '../store/store-list';
import { addDaysTo, formatDate, getCurrentDate, getDifferenceInMilliseconds } from '../utils/date/date-utils';
import { getComparisonOperator, getLogicalOperator } from './requestHelper';
import { type UsePatchInterventionMutationParams } from '../apis/interventions/mutations.types';

const {
  forReceptionInStore,
  forReceptionInRW,
  processedToBeRedirected,
  sendToRW,
  receivedForProcessingInRW,
  deleted,
  paidClosed,
  closureUnderway,
  processedAndAvailable,
  refusedAndAvailable,
  closed,
  estimate,
  refusedToBeRedirected,
  ogeaValidity,
  RWShortage,
  notStarted,
} = InterventionStatus;

export const finishedInterventions = [
  [InterventionStatus.processedAndAvailable],
  [InterventionStatus.paidClosed],
  [InterventionStatus.deleted],
  [InterventionStatus.closureUnderway],
  [InterventionStatus.closed],
  [InterventionStatus.refusedAndAvailable],
];

export const localPendingInterventions = [
  [InterventionStatus.toProcess, InterventionStatus.receivedForProcessingInRW],
  [InterventionStatus.storeShortage, InterventionStatus.RWShortage],
  [InterventionStatus.ogeaValidity],
  [InterventionStatus.estimate],
];

export const localPendingInterventionsRW = [...localPendingInterventions, [InterventionStatus.forReceptionInRW]];

export const distantPendingInterventions = [
  [InterventionStatus.forReceptionInRW],
  [InterventionStatus.receivedForProcessingInRW],
  [InterventionStatus.RWShortage],
  [InterventionStatus.processedToBeRedirected],
  [InterventionStatus.refusedToBeRedirected],
];

export const isLocationDisabled = (stateId: number) =>
  [deleted, paidClosed, closureUnderway, closed].some(interventionState => interventionState === stateId);

export const isInterventionDisabled = (stateId: number) =>
  [deleted, paidClosed, closureUnderway, processedAndAvailable, refusedAndAvailable, closed].some(interventionState => interventionState === stateId);

export const isStateDisabled = (stateId: number, isCreationSite: boolean, interventionType: InterventionType) => {
  if (
    interventionType === InterventionType.web ||
    isInterventionDisabled(stateId) ||
    [forReceptionInRW, forReceptionInStore, processedToBeRedirected, refusedToBeRedirected].some(interventionState => interventionState === stateId)
  ) {
    return true;
  }

  if (isCreationSite) {
    return [receivedForProcessingInRW, RWShortage].some(interventionState => interventionState === stateId);
  }

  return [estimate, sendToRW].some(interventionState => interventionState === stateId);
};

export const isInTransitState = (stateId: number) =>
  [forReceptionInRW, forReceptionInStore, processedToBeRedirected, refusedToBeRedirected].some(interventionState => interventionState === stateId);

export const isShortageStateForbidden = (stateId: number) =>
  isInTransitState(stateId) || [ogeaValidity, notStarted].some(interventionState => interventionState === stateId);

export const handleInterventionFieldUpdate = <T extends Object, Y extends string | number | boolean>(
  model: T,
  value: Y,
  fieldName: string,
  toggleField: (toggle: boolean) => void,
  updateFunction: (model: T) => Record<string, unknown> | Promise<Record<string, unknown>> | void,
  patchIntervention: UseMutateAsyncFunction<unknown, Error, UsePatchInterventionMutationParams, unknown>
) => {
  const docNumber = store.getState().intervention.footer.docNumber;

  if (docNumber) {
    toggleField(true);
    updateIntervention(docNumber, value, fieldName, patchIntervention)
      .then(() => {
        updateFunction(model);
      })
      .catch(error => {
        if (error instanceof Error) {
          externalToastStore.addToast(error.message, colors.red);
        }
      })
      .finally(() => toggleField(false));
  } else {
    updateFunction(model);
  }
};

export const checkGlobalConditions = (
  hasServicesOrSpareParts: boolean,
  isCustomAndWarranty: boolean,
  notInternalAndNoPurchaseDateAndWarranty: boolean
) => {
  if (!hasServicesOrSpareParts) {
    externalToastStore.addToast(translate('toaster.warning.empty.intervention'), colors.red);

    return false;
  }

  if (isCustomAndWarranty) {
    externalToastStore.addToast(translate('error.message.warranty.with.custom.product'), colors.red);

    return false;
  }

  if (notInternalAndNoPurchaseDateAndWarranty) {
    store.dispatch(setMissingFields({ purchaseDate: true }));

    return false;
  }

  return true;
};

export const checkInternalServices = (articles: (Article | undefined)[], contextType: WorkshopContextType | null, strict = false): boolean => {
  if (!contextType?.internal) {
    return true;
  }

  return !articles[strict ? 'some' : 'every'](article => !isPogoAuthorized(contextType.authorizedPogos, article?.gridValueId));
};

export const getMissingFields = (customer: Customer) => {
  const isFrenchCustomer = customer.countryCode === 'FR';
  const missingFields = [];
  const requiredFields = store.getState().userInfo.customerCreationRequiredFields;
  requiredFields.email && !customer.email && missingFields.push(translate('intervention.page.block.customer.email'));
  if (customer.personType === PersonTypes.customer) {
    requiredFields.firstname && !customer.firstname && missingFields.push(translate('searchbox.customer.label.firstname'));
    requiredFields.lastname && !customer.lastname && missingFields.push(translate('searchbox.customer.label.lastname'));
  } else {
    requiredFields.companyName && !customer.companyName && missingFields.push(translate('searchbox.customer.label.company'));
  }
  requiredFields.phone && !customer.phone && missingFields.push(translate('searchbox.customer.label.phone'));
  if (isFrenchCustomer) {
    requiredFields.postalCode && !customer.postalCode && missingFields.push(translate('searchbox.customer.label.postalCode'));
    requiredFields.city && !customer.city && missingFields.push(translate('searchbox.customer.label.city'));
  }
  return missingFields.join(', ');
};

const checkProductAsWarrantiedSparePart = (product: ILinkProduct | null, spareParts: Entity[]) =>
  spareParts
    .filter(sparePart => sparePart.modelCode === product?.id)
    .some(productAsSparePart => productAsSparePart.articles.some(article => article.selected && article.gridValueId === Pogos.GARANTI));

export const checkInterventionConditions = (currency: string) => {
  const state = store.getState();
  const { maxOgeaAmount } = state.intervention;
  const services = state.intervention.natures.flatMap(nature => nature.services);
  const spareParts = state.intervention.natures.flatMap(nature => nature.spareParts);
  const hasServicesOrSpareParts = !!spareParts.length || !!services.length;
  const interventionType = state.intervention.interventionType;
  const customer = state.customer.customer;
  const hasWarranty = findWarranty(services) || findWarranty(spareParts);
  const missingFields = !!customer?.memberId && customer?.personType !== PersonTypes.decathlon && getMissingFields(customer);
  const hasPriceless = findPriceless(services, true) || findPriceless(spareParts, true);
  const isCustomAndWarranty = hasWarranty && !hasKnownProduct(state.catalog.search.product);
  const notInternalAndNoPurchaseDateAndWarranty = !getIsInternalIntervention(state) && hasWarranty && !state.catalog.search.product.purchaseDate;
  const productAsPartUnderWarranty = checkProductAsWarrantiedSparePart(state.catalog.search.product, spareParts);
  const contextType = state.intervention.contextType;
  const existingIntervention = isExistingIntervention(state);

  if (productAsPartUnderWarranty) {
    externalToastStore.addToast(translate('toaster.warning.product.as.spare.part.under.warranty'), colors.red);
    return false;
  }

  if (!checkGlobalConditions(hasServicesOrSpareParts, isCustomAndWarranty, notInternalAndNoPurchaseDateAndWarranty)) {
    return false;
  }

  if (!customer && existingIntervention) {
    externalToastStore.addToast(translate('toaster.warning.customer.mandatory'), colors.red);
    return false;
  }

  if (missingFields) {
    externalToastStore.addToast(`${translate('toaster.warning.customer.data.not.filled')} ${missingFields}`, colors.red);
    return false;
  }

  if (hasPriceless) {
    externalToastStore.addToast(translate('error.message.article.without.price'), colors.red);
    return false;
  }

  if (hasNoStock(spareParts)) {
    externalToastStore.addToast(translate('toaster.warning.editing.invoice.no.stock'), colors.red);
    return false;
  }

  if (isInterventionOgea(interventionType) && getOgeaAmount(services, spareParts) > (maxOgeaAmount ?? Infinity)) {
    const additionalParams = { amount: `${maxOgeaAmount}${currency}` };
    externalToastStore.addToast(translate('toaster.warning.editing.invoice.ogea.unauthorized', additionalParams), colors.red);
    return false;
  }

  if (
    !checkInternalServices(
      services.flatMap(service => service.articles.find(findSelectedArticle) ?? []),
      contextType,
      true
    )
  ) {
    externalToastStore.addToast(translate('error.message.not.authorized.service.for.business'), colors.red);
    return false;
  }
  return true;
};

export const getInterventionTypesFilter = () =>
  Object.keys(InterventionType)
    .filter(value => isNaN(parseInt(value, 10)))
    .map((label, index) => ({ id: index + 1, label: translate(`intervention.page.radio.button.label.${label}` as I18nKey), selected: true }));

export interface CustomerIds {
  memberId: string | null;
}

export const getListOfCustomers = (interventions: WorkshopIntervention[]): CustomerIds[] => {
  const members = interventions.filter(intervention => intervention.memberId).map(intervention => ({ memberId: intervention.memberId }));

  return [...arrayUniqueByKey(members, 'memberId')];
};

export const fillInterventionsInformation = ({
  interventions,
  withoutIdentity = false,
  processes = [],
}: FillInterventionsInformationParams): Promise<Intervention[]> => {
  const itemCodes = [...new Set(interventions.map(intervention => intervention.depositedProduct.itemCode))].filter(data => !!data);

  const knownCustomers = getListOfCustomers(interventions);

  const customersPromise: Promise<CustomersLightDetails[]> = withoutIdentity
    ? Promise.resolve(
        knownCustomers.map(customer => ({
          customers: customer.memberId ?? undefined,
          phone: '',
          customerName: '',
        }))
      )
    : getCustomersLightDetails(knownCustomers);

  const productsPromise = Promise.all(
    itemCodes.map(itemCode =>
      itemCode
        ? getArticlesDetails([itemCode])
            .then(([model]) => ({ itemCode, productName: model.modelLib }))
            .catch(() => null)
        : Promise.resolve(null)
    )
  );

  return Promise.all([customersPromise, productsPromise]).then(([customers, products]) =>
    interventions.map(intervention => {
      const customerInfo = () => {
        const filteredCustomer = [...customers].filter(data => !!data?.memberId).find(data => data.memberId === intervention.memberId);

        if (!intervention.memberId && !intervention.contextId) {
          return {
            customer: translate('planning.table.client.error'),
            phoneNumber: '',
          };
        }

        return intervention.memberId
          ? {
              customer: filteredCustomer?.customerName ?? '',
              phoneNumber: filteredCustomer?.phone ?? '',
            }
          : {
              customer: getDecathlonCustomerName(intervention.contextId),
              phoneNumber: '',
            };
      };
      const product = intervention.depositedProduct.itemCode
        ? products.filter(data => !!data).find(data => data?.itemCode === intervention.depositedProduct.itemCode)
        : { productName: intervention.depositedProduct.label };
      // todo remove static redux
      const allStore = selectAllStores(store.getState());
      const creationSite = allStore.find(storeSite => storeSite.id === intervention.creationSite);
      const realisationSite = allStore.find(storeSite => storeSite.id === intervention.realisationSite);

      const constructedIntervention: Intervention = {
        interventionNumber: intervention.documentNumber,
        contextId: intervention.contextId,
        invoiceNumber: intervention.invoiceNumber,
        processLabel: processes.find(process => process.id === intervention.familyId)?.label ?? null,
        processId: intervention.familyId,
        memberId: intervention.memberId,
        ...customerInfo(),
        product: (product ? product.productName : intervention.depositedProduct.itemCode?.toString()) ?? '',
        productCode: intervention.depositedProduct.itemCode,
        customerPledge: intervention.availabilityDate ?? null,
        creationDate: intervention.creationDate,
        realisationSiteId: realisationSite?.id,
        realisationSiteName: realisationSite?.name ?? '',
        creationSiteId: creationSite?.id,
        creationSiteName: creationSite?.name ?? '',
        estimatedTime: intervention.totalTheoreticalTime,
        interventionState: intervention.stateLabel ?? '',
        interventionStateId: intervention.stateId,
        location: intervention.depositedProduct.location,
        interventionType: intervention.interventionType,
        externalReferenceNumber: intervention.externalReferenceNumber,
        services: intervention.services,
        spareParts: intervention.spareParts,
        productPurchaseDate: intervention.depositedProduct.purchaseDate,
        totalPrice: intervention.totalPriceWithTaxes,
        currency: intervention.currencyCode,
        events: intervention.events,
        validateTotalPrice: intervention.validateTotalPrice,
      };
      return constructedIntervention;
    })
  );
};

export const buildInterventionsByIds = (interventionIds: string[], withoutIdentity = false): Promise<Intervention[]> =>
  getInterventionsByIds(interventionIds, ['state'])
    .then(interventions => interventions.filter(intervention => intervention !== null))
    .then(interventions => fillInterventionsInformation({ interventions, withoutIdentity }));

export const loadNextInterventions = (
  interventions: Intervention[],
  interventionIdsToGet: string[][],
  setInterventionIdsToGet: (ids: string[][]) => void,
  setIsLoading: (status: boolean) => void,
  initiatePlanning: (planning: Intervention[]) => void
) => {
  if (interventionIdsToGet.length) {
    setIsLoading(true);
    buildInterventionsByIds(interventionIdsToGet[0])
      .then(nextInterventions => initiatePlanning([...interventions].concat(nextInterventions)))
      .catch(error => externalToastStore.addToast(error, colors.red))
      .finally(() => setIsLoading(false));
    setInterventionIdsToGet([...interventionIdsToGet].splice(1, interventionIdsToGet.length));
  }
};

export const getInterventionIds = (processes: number[], fromDate: Date, toDate: Date): Promise<string[]> =>
  getInterventionsIds({ processes, fromDate, toDate }).then(toDoEvents =>
    [...toDoEvents]
      .sort((toDoEventA, toDoEventB) => getDifferenceInMilliseconds({ firstDate: toDoEventA.date, secondDate: toDoEventB.date }))
      .flatMap(toDoEvent => toDoEvent.interventionIds)
  );

export const getSortedInterventions = (sortedPlanning: Intervention[], sortElement: SortElement) => {
  const { element, status } = sortElement;
  switch (element) {
    case 'customer':
    case 'product':
    case 'location':
    case 'interventionState':
    case 'creationSiteName':
    case 'interventionNumber':
      return [...sortedPlanning].sort((a, b) =>
        status ? (a[element] || '').localeCompare(b[element] || '') : (b[element] || '').localeCompare(a[element] || '')
      );
    case 'creationDate':
    case 'customerPledge':
      return [...sortedPlanning].sort((a, b) => {
        const firstDate = a[element];
        const secondDate = b[element];

        if (!firstDate && !secondDate) {
          return 0;
        }

        if (!firstDate) {
          return 1;
        }

        if (!secondDate) {
          return -1;
        }

        if (status) {
          return getDifferenceInMilliseconds({ firstDate, secondDate });
        }

        return getDifferenceInMilliseconds({ firstDate: secondDate, secondDate: firstDate });
      });
    case 'estimatedTime':
      return [...sortedPlanning].sort((a, b) => (status ? (a[element] || 0) - (b[element] || 0) : (b[element] || 0) - (a[element] || 0)));
    default:
      return sortedPlanning;
  }
};

export interface LoadInterventionDataProps {
  interventionId: string;
  languages: Language[];
  isInvoice: boolean;
  selectedSiteId: number;
  workshopInterventionContextTypes: WorkshopContextType[];
}

export const duplicateIntervention = () => {
  const state = store.getState();
  const selectedSite = state.userInfo.selectedSite;
  const creationSite: MasterDataStore = {
    id: selectedSite.id,
    name: selectedSite.shortName,
  };
  const isPaidClosed = state.intervention.footer.state.stateId === InterventionStatus.paidClosed;

  store.dispatch(
    initIntervention({
      ...initialState,
      contextType: state.intervention.contextType,
      interventionType: InterventionType.standard,
      creationSite,
      realizationSite: {
        ...creationSite,
        delay: state.intervention.realizationSite?.delay,
      },
      footer: {
        ...initialState.footer,
        pledgeDate: isPaidClosed ? getFuturePledgeDate(getCurrentDate()) : state.intervention.footer.pledgeDate,
      },
    })
  );
  store.dispatch(
    updateCatalogSearch({
      product: { ...initialProductCatalog },
      breadcrumb: [initialBreadcrumb()],
      fetchNeeded: true,
      defaultImageUrl: undefined,
    })
  );
  externalToastStore.addToast(translate('toaster.intervention.duplication.success'));
};

export const isTraceabilityNumberInvalid = (traceabilityNumber: string) => traceabilityNumber.length < 9;

export const formatStoreIdForWBO = (storeIdToFormat: number | undefined) => {
  const paddedStoreId = storeIdToFormat?.toString().padStart(5, '0');
  return `007${paddedStoreId}${paddedStoreId}`;
};

export const convertAlertType = ({ cell_phone_alert, email_alert }: ConvertCustomerAlertType) => {
  if (cell_phone_alert && email_alert) {
    return CustomerAlertType.BOTH;
  }
  if (cell_phone_alert && !email_alert) {
    return CustomerAlertType.SMS;
  }
  if (email_alert && !cell_phone_alert) {
    return CustomerAlertType.EMAIL;
  }
  return CustomerAlertType.NONE;
};

export const buildGlobalSearchInterventionFromRaw = (interventionRaw: WboInterventionSearchRaw): WorkshopIntervention => ({
  availabilityDate: interventionRaw.pledge_date ? new Date(interventionRaw.pledge_date) : null,
  creationDate: new Date(interventionRaw.created_date),
  creationSite: Number(interventionRaw.creation_site.slice(-5)),
  currencyCode: interventionRaw.currency_code,
  customerAlertType: convertAlertType(interventionRaw.customer),
  customerLanguage: interventionRaw.language ?? '',
  depositedProduct: interventionRaw.model
    ? {
        itemCode: interventionRaw.model.item_code,
        purchaseDate: interventionRaw.model.purchase_date ? new Date(interventionRaw.model.purchase_date) : undefined,
        categoryId: interventionRaw.model.category_id,
        label: interventionRaw.model.product_label ?? '',
        location: interventionRaw.model.location,
      }
    : {},
  documentNumber: interventionRaw.document_number,
  familyId: interventionRaw.family_id,
  interventionType: interventionRaw.type.id,
  isLocal: interventionRaw.is_local,
  memberId: interventionRaw.customer.member_id ?? undefined,
  realisationSite: Number(interventionRaw.realisation_site.slice(-5)),
  totalPriceWithTaxes: interventionRaw.total.price_with_taxes,
  totalTheoreticalTime: interventionRaw.total.theoretical_time,
  stateId: interventionRaw.states.state.id,
  contextId: interventionRaw.ref_context?.id,
  events: interventionRaw.events.map(event => ({
    date: event.date,
    eventId: event.event_id,
  })),
  validateTotalPrice: interventionRaw.validate_total_price,
});

const includes = [
  'validate_total_price',
  'pledge_date',
  'created_date',
  'creation_site',
  'currency_code',
  'customer.cell_phone_alert',
  'customer.email_alert',
  'customer.member_id',
  'language',
  'model.id',
  'model.product_label',
  'model.created_date',
  'model.item_code',
  'model.location',
  'model.category_id',
  'model.purchase_date',
  'document_number',
  'family_id',
  'type.id',
  'is_local',
  'realisation_site',
  'store_comment',
  'total.price_with_taxes',
  'total.theoretical_time',
  'ref_context.id',
  'ref_context.name',
  'states.state.id',
  'events.event_id',
  'events.date',
];

interface ChooseSiteToUseParams {
  isRealizationSite: boolean;
  stateId: number;
  storeId: number;
  childStoreId?: number;
  isRegionalWorkshop?: boolean;
}

const chooseSiteToUse = ({ storeId, childStoreId, isRegionalWorkshop, isRealizationSite, stateId }: ChooseSiteToUseParams) => {
  const hasRelatedStore =
    storeId && isRegionalWorkshop && isRealizationSite && finishedInterventions.map(intervention => intervention[0]).includes(stateId);

  if (hasRelatedStore && childStoreId) {
    return [
      ['creation_site', formatStoreIdForWBO(childStoreId)],
      ['realisation_site', formatStoreIdForWBO(storeId)],
    ];
  }

  if (hasRelatedStore && !childStoreId) {
    return [['creation_site', formatStoreIdForWBO(storeId)]];
  }

  if (isRealizationSite) {
    return [['realisation_site', formatStoreIdForWBO(storeId)]];
  }

  return [['creation_site', formatStoreIdForWBO(storeId)]];
};

export const constructUrlParamForGlobalSearch = ({
  stateIds,
  nextParams,
  isRealizationSite,
  storeId,
  isRegionalWorkshop,
  fromDate,
  toDate,
  processes,
  interventionTypes,
  filteredInternal,
  childStoreId,
}: UrlForGlobalSearchParams) => {
  const internalContexts = [InterventionContextId.newInternal, InterventionContextId.secondLifeInternal, InterventionContextId.locationInternal];
  const stateIdParams = stateIds.join(';');
  const criterias = [
    ['includes', includes.join(',')],
    ['sort', 'created_date,ASC'],
    ['states.state.id', getComparisonOperator(stateIdParams, 'in')],
    ...chooseSiteToUse({ storeId, childStoreId, isRealizationSite, isRegionalWorkshop, stateId: stateIds[0] }),
    ...(processes ? [['family_id', getComparisonOperator(processes.map(process => process.id).join(';'), 'in')]] : []),
    ...(interventionTypes
      ? [['type.id', getComparisonOperator(interventionTypes.map(interventionType => interventionType.id).join(';'), 'in')]]
      : []),
    ...(filteredInternal ? [['ref_context.id', getComparisonOperator(internalContexts.join(';'), 'in')]] : []),
    ...(fromDate ? [['events.date', getComparisonOperator(formatDate({ date: fromDate, strFormat: 'yyyy-MM-dd' }), 'gte')]] : []),
    ...(stateIdParams === '3'
      ? [['events.event_id', getComparisonOperator(InterventionsEventsType.ITV_INVOICE_CREATION_DATE.toString(), 'in')]]
      : []),
    ...(toDate
      ? [
          [
            'events.date',
            getLogicalOperator(
              getComparisonOperator(formatDate({ date: addDaysTo({ date: toDate, quantity: 1 }), strFormat: 'yyyy-MM-dd' }), 'lte'),
              'and'
            ),
          ],
        ]
      : []),
  ];
  const params = new URLSearchParams(nextParams);

  criterias.forEach(([key, value]) => params.append(key, value));

  return `interventions?${params.toString()}`;
};
