import React from 'react';

import { AppButton, type AppButtonProps } from './AppButton';

export interface PrimaryButtonProps extends Omit<AppButtonProps, 'variant'> {}

export const PrimaryButton = ({ children, ...rest }: PrimaryButtonProps) => (
  <AppButton {...rest} variant="primary">
    {children}
  </AppButton>
);
