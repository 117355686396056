/* eslint-disable no-console */
import React, { useEffect } from 'react';
import cache from 'lscache';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { authenticationStorageKey, sessionIdentifierKey } from './constants';
import { useAppDispatch } from '../../store/store';
import { addPersonalUserInfo } from '../../store/userInfoSlice';
import { selectToken } from '../../store/selectors/configuration';
import { selectUserInfo } from '../../store/selectors/userInfo';
import { type Profile } from './types';
import { minutesToMidnight } from '../../utils/utils';
import { selectAuthService } from '../../store/selectors/auth';
import { updateToken } from '../../store/configSlice';
import { buildHeaders, buildRequest, buildUrl, fetchRequest } from '../../utils/apis/RestApi';
import { isNewAuthVersion } from '../../utils/storage/localStorage';

export interface Props {
  children: JSX.Element;
}

export const Authentication = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const token = useSelector(selectToken);
  const userInfo = useSelector(selectUserInfo);
  const authService = useSelector(selectAuthService);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isNewAuth = isNewAuthVersion();

  const saveUserInfo = ({ displayName, preferredLanguage, uid, jobname, sitepartynumber }: Profile) => {
    dispatch(
      addPersonalUserInfo({
        displayName,
        preferredLanguage,
        uid,
        jobname,
        sitePartyNumber: sitepartynumber,
      })
    );
  };

  useEffect(() => {
    if (isNewAuth) {
      (async () => {
        try {
          const userInfoReq = buildRequest(buildUrl('ICARE_BACK', 'auth/userinfo'), 'GET', buildHeaders('ICARE_BACK', 'Bearer'));
          const response = await fetchRequest(userInfoReq);
          const { user, accessToken } = await response.json();

          saveUserInfo(user);
          dispatch(updateToken(accessToken));
        } catch (error) {
          console.error(error);
        }
      })();
    } else {
      (async () => {
        /**
         * Avoid the user to claim a new Authentication Token if he's already logged in.
         */
        const currentUser = await authService.getUser();

        if (currentUser) {
          saveUserInfo(currentUser.profile);
        }

        /**
         * When the user does not have a token in cache nor user available,
         * We'll retrieve the user and store it both in cache and store.
         */
        if (!token || !currentUser) {
          try {
            const user = await authService.signinRedirectCallback();

            saveUserInfo(user.profile);
            dispatch(updateToken(user.access_token));

            if (user.expires_in && user.id_token) {
              cache.set(`${authenticationStorageKey}-token`, user.access_token, user.expires_in / 60);
              cache.set(sessionIdentifierKey, JSON.parse(window.atob(user.id_token.split('.')[1].replace('_', '/')))['pi.sri'], minutesToMidnight());
            }

            if (searchParams.has('code')) {
              navigate('/');
            }
          } catch (error) {
            await authService.signinRedirect();
          }
        }
      })();

      /**
       * When the token has expired, we'll try to silent signin the user again.
       * If any errors occurs while refreshing the token, we'll get the new user infos directly and add it into the store.
       */
      authService.events.addAccessTokenExpired(async () => {
        const currentTime = new Date().toLocaleTimeString();

        console.warn(`[${currentTime}] Token expired, trying to silent signin the user again...`);

        try {
          await authService.removeUser();
          await authService.signinSilent();
        } catch (err) {
          console.warn(`[${currentTime}] Silent signin failed, trying to get the user infos directly...`);
          const newUserInfo = await authService.getUser();

          console.warn(`[${currentTime}] New user infos retrieved, updating the store...`, { newUserInfo });

          if (newUserInfo) {
            saveUserInfo(newUserInfo.profile);
            dispatch(updateToken(newUserInfo.access_token));
          }
        }
      });
    }
  }, []);

  if (userInfo.displayName) {
    return children;
  }

  return (
    <div style={{ display: 'flex', height: '90vh', textAlign: 'center', flexDirection: 'column', justifyContent: 'center' }}>
      Waiting for authentication...
    </div>
  );
};
