import React, { type MouseEvent } from 'react';
import styled from 'styled-components';
import cache from 'lscache';
import clsx from 'clsx';
import { VpIconButton } from '@vtmn-play/react';
import { VpCloseIcon } from '@vtmn-play/icons/react';

import { createLog } from '../../apis/logger';
import { colors } from './ToastProvider';
import useToaster, { type ToastProps } from '../hooks/useToaster';

import './toast.css';

const ToastWrapper = styled.div`
  z-index: 10000;
  width: 320px;
  max-width: 100vw;
  padding: 1rem;
  position: fixed;
  top: 0;
  right: 0;
`;

export const Toast = styled.div`
  opacity: 0.8;
  border-radius: 0.5rem;
  background-color: ${props => (props.color ? (colors as any)[props.color] || props.color : colors.green)};
  color: ${colors.white};
  padding: 1rem;
  text-align: left;
  transform-origin: bottom right;
  transition: all 0.5s ease-in-out;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  margin-top: 0.5rem;
  white-space: pre-line;

  & h1 {
    font-size: 1.2rem;
    margin: 0;
  }

  &:hover {
    opacity: 1;
    transform: scale(1.025);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  }

  &.reload {
    cursor: pointer;
  }
`;

const logger = createLog('toaster');

const Toaster = () => {
  const { toasts, removeToast } = useToaster();

  const checkContent = (content: string | Error) => {
    if (content instanceof Error) {
      logger.log(content);
      return content.message;
    }

    return content;
  };

  const reloadPage = () => {
    const languageStorageKey = 'language-storage-key';
    const storeStorageKey = 'store-storage-key';
    const storedLanguages = JSON.parse(cache.get(languageStorageKey));
    const storedStore = cache.get(storeStorageKey);
    while (Object.keys(localStorage).some(storageKey => storageKey.includes('lscache'))) {
      cache.flush();
    }
    cache.set(languageStorageKey, JSON.stringify(storedLanguages));
    cache.set(storeStorageKey, storedStore);
    location.reload();
  };

  const handleClick = (event: MouseEvent, toast: ToastProps) => {
    event.stopPropagation();
    toast.reload && reloadPage();
  };

  const toastsDisplay = toasts.map(toast => (
    <Toast
      id="toast"
      data-testid="toaster"
      className={clsx('toast', { reload: toast.reload })}
      color={toast.color}
      key={toast.id}
      onClick={event => handleClick(event, toast)}
    >
      <VpIconButton className="close-message" data-testid="close-toaster" size="small" variant="camouflage" onClick={() => removeToast(toast.id)}>
        <VpCloseIcon aria-label="Close toast message" />
      </VpIconButton>
      {checkContent(toast.content)}
    </Toast>
  ));

  return toasts.length > 0 ? <ToastWrapper role="alert">{toastsDisplay}</ToastWrapper> : null;
};

export default Toaster;
